<!--
 * @Descripttion:
 * @version: X1.1
 * @Author: 李波
 * @Date: 2020-06-15 18:55:53
 * @LastEditors: 霍铁樱
 * @LastEditTime: 2021-01-06 22:27:03
-->
<template>
  <div class="content">
    <div class="top">
      <div class="top_search">
        <div class="top_search-left" @click="companyClcik">
          {{
            shortname
              ? shortname.length > 4
                ? shortname.substr(0, 4)
                : shortname
              : "保险公司"
          }}
          <img src="@/assets/newskin/xia.png" alt="" />
        </div>
      </div>
      <!--  搜索框  -->
      <div class="searchbox clearfix">
        <div class="searchline clearfix">
          <button class="btnicon">
            <md-icon name="search" size="lg" color="#D6D6D6"></md-icon>
          </button>
          <input
            type="text"
            placeholder="请输入产品名称"
            v-model="searchValue"
            @keyup.13="yourmethod"
            v-on:focus="getFocus"
          />
          <button class="btnclear" @click="clearinput" v-show="isclear">
            <md-icon name="fail" size="lg" color="#666"></md-icon>
          </button>
        </div>
        <div class="searchTxt" @click="yourmethod">搜索</div>
      </div>
      <!-- <div></div> -->
    </div>
    <div class="con_centet">
      <div class="con_center-left">
        <md-scroll-view
          class="contentscroll"
          ref="scrollView"
          auto-reflow
          :scrolling-x="false"
        >
          <div>
            <div class="cen_left" @click="productCategories(true)">
              <div
                v-show="isProductCategoriesTitle"
                class="cen_left-title"
                :style="`background-color:${getTheme}`"
              ></div>
              <div
                :class="
                  isProductCategoriesTitle ? 'cen_left-text' : 'cen_left-text2'
                "
              >
                全部产品
              </div>
            </div>
            <div
              class="cen_left"
              :class="index + 1 == productClass.length ? 'cen_left-bom' : ''"
              v-for="(item, index) in productClass"
              :key="index"
              @click="productCategories(false, item)"
            >
              <div
                :class="item.ischoose ? 'cen_left-title2' : ''"
                v-if="item.ischoose"
              >
                <div class="cen_left-title"></div>
              </div>
              <div :class="item.ischoose ? 'cen_left-text' : 'cen_left-text2'">
                {{ item.typename }}
              </div>
            </div>
          </div>
        </md-scroll-view>
      </div>
      <div class="con_center-right" :class="isNull ? 'cen_right-bac' : ''">
        <md-scroll-view
          class="contentscroll"
          ref="scrollView"
          auto-reflow
          :scrolling-x="false"
          @end-reached="loadMorechange"
          v-show="!isNull"
        >
          <div
            class="cen_right"
            @click="insuredClick(item)"
            v-for="(item, index) in cplist"
            :key="index"
            :class="index == cplist.length - 1 ? 'cen_right-bom' : ''"
          >
            <div class="cen_right-top">
              <p class="top-title">
                {{ item.prodname }}
              </p>
              <div class="product-features">
                <div
                  @click.stop="insuredClick(item)"
                  v-if="item.tbflag == '0'"
                  :style="`color:${getTheme1.aDA8A3F};background-color:${getTheme1.a00A0E9}`"
                >
                  在线出单
                </div>
                <div
                  @click.stop="jhsClick(item)"
                  v-if="item.jhsflag == '0'"
                  :style="`color:${getTheme1.aDA8A3F};background-color:${getTheme1.ada8b40}`"
                >
                  计划书
                </div>
              </div>
              <span
                class="product-commission"
                @click.stop="fristcommission(item)"
                v-if="item.abtComHxCpTage && item.abtComHxCpTage.status === '0'"
                :style="`color:${getTheme};background-color:${
                  item.abtComHxCpTage ? item.abtComHxCpTage.tagcolor : '#fff'
                }`"
                >首佣<md-icon name="arrow-down"></md-icon
              ></span>
              <!-- 产品介绍  -->
              <p class="product-introduce">
                {{ item.pdesc }}
              </p>
            </div>
            <div class="cen_right-bottom">
              <!-- :style="`background-color: ${getTheme};box-shadow: 0px 5px 20px 1px ${getTheme}` -->
              <div
                class="cen_qd"
                :style="`color:${getTheme};background:${getTheme1.aEEF9FF};border: 1px solid ${getTheme1.aBBE2F8};background-image: linear-gradient(${getTheme1.aEEF9FF},${getTheme1.aBBE2F8})`"
                @click.stop="qdClick(item)"
              >
                签单必看
              </div>
              <div class="right_bom-cen">
                <div class="cen-img" v-if="item.logo">
                  <img :src="ossurl + item.logo" alt="" />
                </div>
                <!-- <div class="cen-img"><img :src="ossurl+item.promotionpriceurl"
                       alt=""></div> -->
                <div class="cen_company">{{ item.shortname }}</div>
              </div>
            </div>
          </div>
          <!-- <md-scroll-view-more slot="more" :is-finished="listFinished"></md-scroll-view-more> -->
        </md-scroll-view>
        <div class="nodata" v-show="isNull">
          <img class="kongimg" src="@/assets/newskin/null.jpg" alt />
          <p class="asdasdqwe">目前还没有内容哦~</p>
        </div>
      </div>
    </div>
    <div class="bottomframe">
      <md-popup class="inner-popup" v-model="isPopupShow" position="bottom">
        <md-popup-title-bar
          only-close
          large-radius
          :title="itemtk.prodname"
          title-align="center"
          @cancel="hidePopUp()"
        ></md-popup-title-bar>
        <div v-if="isloadingShow" class="icdoling">
          <md-icon
            name="spinner"
            color="blue"
            size="lg"
            style="-webkit-filter: invert(1)"
          ></md-icon>
        </div>
        <div class="disstable" v-if="!isloadingShow">
          <div class="asdasd" v-if="promotionFeeInfo.length == 0">
            <p class="asdasdqwe">- 暂无数据 -</p>
          </div>
          <div class="tgf_box" v-else>
            <table
              class="pftable"
              v-for="(item, index) in promotionFeeInfo"
              :key="index"
            >
              <tr v-if="index == 0">
                <th>险种名称</th>
                <th
                  v-for="(itemone, inde) in promotionFee"
                  :class="itemone == '1-1' ? 'firstcos' : ''"
                  :key="inde"
                >
                  {{ inde }}
                </th>
              </tr>
              <tr v-for="(itemtwo, ind) in item.list" :key="ind">
                <td :rowspan="item.list.length" v-if="ind == 0">
                  {{ item.name }}
                </td>
                <td
                  v-for="(itemone, indexone) in promotionFee"
                  :class="itemone == '1-1' ? 'firstcos' : ''"
                  :key="indexone"
                >
                  {{ item.list[ind][itemone] }}
                </td>
              </tr>
            </table>
            <!-- <img :src="ossurl+tgfimg" alt /> -->
          </div>
        </div>
      </md-popup>
    </div>
    <!-- <div></div> -->
    <nhsx></nhsx>
    <!-- 保险公司筛选 -->
    <md-popup v-model="isSelectorShow" position="left" class="md-popupleft">
      <div class="md-examplepopup">
        <div class="popupleft-title">保险公司</div>
        <div
          style="
            height: 80vh;
            overflow: auto;
            padding-left: 0.4rem;
            padding-bottom: 0.5rem;
          "
        >
          <div class="titlecontent">
            <div v-for="(item, index) in insuranceCompany" :key="index">
              <div
                :class="!item.ischoose ? 'company-title' : 'companytitle'"
                @click="choosefl(item, index)"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-cz">
        <div class="bottom-chongz" @click="reset">
          <div class="chongzhi">重置</div>
        </div>
        <div
          class="bottom-qued"
          :style="`backgroundColor: ${colorprimary};`"
          @click="onSelectorChoose"
        >
          <div class="queding">确定</div>
        </div>
      </div>
    </md-popup>
    <!-- <div><img src="@/assets/newfeature/cpk/down.png" alt=""></div> -->
  </div>
</template>

<script>
// import {
//   sxchoseList,
//   showList,
//   choseList,
//   getInsureUrl
// } from '@/api/agent/agent/index'
import {
  sxchoseList,
  productList,
  showList,
  choseList,
  getInsureUrl,
} from "@/api/agent/agent/index";
// import { supplieList, choseList, productList, getCPXZRate } from '@/api/newfeature/cpk/index'
// import { DoRecord } from '@/api/abt/customerOperation/common/index'
import { getStorage } from "@/lib/util";
import loadMorechange from "@/mixins/loadMorechange";
import { Toast } from "mand-mobile";
import nhsx from "@/views/basic/home/component/nhsx";
import initWebSocket from "@/mixins/websock";
export default {
  mixins: [initWebSocket, loadMorechange], // 下拉加载的
  components: { nhsx },
  data() {
    return {
      loadflag: false,
      getBossurl: "",
      ossurl: "",
      isPopupShow: false,
      isloadingShow: false,
      promotionFeeInfo: [],
      promotionFee: [],
      isSelectorShow: false,
      insuranceCompany: [],
      searchValue: "",
      isclear: false,
      colorprimary: "",
      baseid: "",
      shortname: "",
      prodtype: "",
      typename: "",
      getTheme1: "",
      getTheme: "",
      productClass: [],
      isProductCategoriesTitle: true,
      listFinished: false,
      total: 0,
      listpageNo: 1,
      size: 10,
      listtotal: 0,
      listtotalPage: 0,
      cplist: [],
      isNull: false,
      tgfimg: "",
      itemtk: {},
      user: {},
      cliflag: true,
    };
  },
  created() {
    this.getBossurl = getStorage("tbossurl", "");
    this.ossurl = getStorage("ossurl", "");
    this.getTheme1 = getStorage("theme1", "");
    this.getTheme = getStorage("theme", "");
    this.colorprimary = getStorage("theme", "");
    this.user = getStorage("u_s", {});
    if (this.$route.params.search) {
      this.searchValue = this.$route.params.search;
    }
    this.getProductClass();
    this.getCompanyClass();
    this.getData();
    this.cplist = getStorage("productList");
  },
  methods: {
    // 打开保险公司选择弹窗
    companyClcik() {
      this.$nextTick(() => {
        this.isSelectorShow = true;
      });
    },
    // 获取产品分类数据
    getProductClass() {
      choseList().then((res) => {
        this.productClass = res.data.data;
        this.productClass.forEach((item, index) => {
          this.$set(item, "value", item.typecode);
          this.$set(item, "text", item.typename);
          this.$set(item, "ischoose", false);
          if (this.$route.query.prodtype == item.typecode) {
            this.$set(item, "ischoose", true);
            this.prodtype = item.typecode;
            this.typename = item.typename;
          } else {
            this.$set(item, "ischoose", false);
          }
        });
      });
    },
    // 获取保险公司分类数据
    // getCompanyClass () {
    //   supplieList().then(res => {
    //     this.insuranceCompany = res.data.data
    //     this.insuranceCompany.forEach((item, index) => {
    //       this.$set(item, 'value', item.baseid)
    //       this.$set(item, 'text', item.shortname)
    //       if (this.$route.query.baseid == item.baseid) {
    //         this.$set(item, 'ischoose', true)
    //         this.shortname = item.shortname
    //       } else {
    //         this.$set(item, 'ischoose', false)
    //       }
    //     })
    //   })
    // },
    getCompanyClass() {
      if (this.user.comid == "NHWX") {
        sxchoseList().then((res) => {
          this.insuranceCompany = res.data.data;
          this.insuranceCompany.forEach((item, index) => {
            this.$set(item, "value", item.baseid);
            this.$set(item, "text", item.shortname);
            if (this.$route.query.baseid == item.baseid) {
              this.$set(item, "ischoose", true);
              this.shortname = item.shortname;
            } else {
              this.$set(item, "ischoose", false);
            }
          });
        });
      } else {
        productList().then((res) => {
          this.insuranceCompany = res.data.data;
          this.insuranceCompany.forEach((item, index) => {
            this.$set(item, "value", item.baseid);
            this.$set(item, "text", item.shortname);
            if (this.$route.query.baseid == item.baseid) {
              this.$set(item, "ischoose", true);
              this.shortname = item.shortname;
            } else {
              this.$set(item, "ischoose", false);
            }
          });
        });
      }
    },
    // 选择保险公司分类
    choosefl(item, index) {
      this.insuranceCompany.forEach((item, index) => {
        item.ischoose = false;
      });
      item.ischoose = !item.ischoose;
      this.baseid = item.value;
      this.shortname = item.text;
    },
    // 重置保险公司选择
    reset() {
      this.insuranceCompany.forEach((item, index) => {
        item.ischoose = false;
      });
      this.baseid = "";
      this.shortname = "";
    },
    // 选择产品分类
    productCategories(type, val) {
      this.productClass.forEach((item, index) => {
        item.ischoose = false;
      });
      if (type) {
        this.isProductCategoriesTitle = true;
        this.prodtype = "";
      } else {
        this.isProductCategoriesTitle = false;
        this.prodtype = val.typecode;
        val.ischoose = !val.ischoose;
      }
      // this.$router.replace(`${this.$route.path}?baseid=${this.baseid ? this.baseid : ''}&searchValue=${this.searchValue ? this.searchValue : ''}&prodtype=${this.prodtype ? this.prodtype : ''}`)
      this.listpageNo = 1;
      this.getData();
    },
    // 获取产品列表
    getData() {
      // let data = {
      //   page: this.listpageNo,
      //   size: this.size,
      //   baseid: this.baseid,
      //   search: this.searchValue,
      //   prodtype: this.prodtype
      // }
      // productList(data).then(res => {
      //   this.cplist = this.cplist.length == 0
      //     ? res.data.data.rows : (data.page == 1 ? res.data.data.rows : this.cplist.concat(res.data.data.rows))
      //   this.listtotal = res.data.data.total
      //   this.listtotalPage = res.data.data.totalpage
      //   if (this.cplist.length == 0) {
      //     this.isNull = true
      //   } else {
      //     this.isNull = false
      //   }
      //   if (this.listtotalPage <= this.listpageNo) {
      //     this.listFinished = true
      //   } else {
      //     this.listFinished = false
      //     this.$refs.scrollView.finishLoadMore()
      //   }
      //   this.loadflag = true
      // }).finally(() => {
      //   Toast.hide()
      //   this.isSelectorShow = false
      // })
      let data = {
        page: this.listpageNo,
        size: this.size,
        baseid: this.baseid,
        prodtype: this.prodtype,
        age: this.shortAgename,
        search: this.searchValue,
        type: this.user.comid == "NHWX" ? "0" : "",
      };
      showList(data)
        .then((res) => {
          this.cplist =
            this.cplist.length == "0"
              ? res.data.data.rows
              : data.page == 1
              ? res.data.data.rows
              : this.cplist.concat(res.data.data.rows);

          if (this.cplist.length == 0) {
            this.isNull = true;
          } else {
            this.isNull = false;
          }
          this.listtotal = res.data.data.total;
          this.listtotalPage = res.data.data.totalpage;
          if (this.listtotalPage <= this.listpageNo) {
            this.listFinished = true;
          } else {
            this.listFinished = false;
            this.$refs.scrollView.finishLoadMore();
          }
          this.loadflag = true;
        })
        .finally(() => {
          Toast.hide();
          this.isSelectorShow = false;
          this.isChoseShow = false;
        });
    },
    // 选择保险公司
    onSelectorChoose() {
      // this.$router.replace(`${this.$route.path}?baseid=${this.baseid ? this.baseid : ''}&searchValue=${this.searchValue ? this.searchValue : ''}&prodtype=${this.prodtype ? this.prodtype : ''}`)
      this.listpageNo = 1;
      this.getData();
    },
    // 点击搜索
    yourmethod() {
      // this.$router.replace(`${this.$route.path}?baseid=${this.baseid ? this.baseid : ''}&searchValue=${this.searchValue ? this.searchValue : ''}&prodtype=${this.prodtype ? this.prodtype : ''}`)
      this.listpageNo = 1;
      this.getData();
    },
    // 搜索框失焦事件
    getFocus() {
      this.isclear = true;
    },
    // 清空搜索框
    clearinput() {
      this.searchValue = "";
      this.isclear = false;
    },
    // 点击在线出单
    // insuredClick (val) {
    //   // 0为在线出单产品1为非在线出单
    //   if (val.tbflag === '0') {
    //     // 导入产品跳转不过验签
    //     if (val.drflag === '1') {
    //       window.location.href = val.comtburl
    //       return
    //     }
    //     if (!val.comtburl.includes('param=')) {
    //       Toast.info('请先配置投保链接')
    //       return
    //     }
    //     // 在线出单产品进行跳转链接处理，跳转到产品详情页
    //     this.$store.dispatch('SALES_PERMISSION', {
    //       url: val.comtburl
    //     }).then(res => {
    //       if (res.tburl) {
    //         this.behaviorRecord(res)
    //         // window.location.href = res.tburl
    //       } else {
    //         window.location.href = val.comtburl
    //       }
    //     })
    //   } else {
    //     // 非在线出单产品调转到展示页
    //     this.$router.push({
    //       name: 'showhostproduct',
    //       params: {
    //         product: val.showhttp ? val.showhttp : '<p style="margin: 20px auto;">暂无数据</p >',
    //         iconhttp: '',
    //         prodname: val.prodname
    //       }
    //     })
    //   }
    // },
    // // 获取恒大链接
    // getInsureUrl (data) {
    //   getInsureUrl(data).then(response => {
    //     window.location.href = response.data.data
    //   }).finally(() => {
    //     this.cliflag = true
    //   })
    // },
    insuredClick(val) {
      console.log(val);

      if (!this.cliflag) {
        return;
      }
      // setStorage('productScollTop',this.productScollTop)
      // this.productScollTop = this.$refs.scrollView.getOffsets().top
      this.cliflag = false;
      // 判断如果不是业务员并且以持证，
      if (
        getStorage("u_s", "").rytype == "W" &&
        getStorage("u_s", "").status == "6"
      ) {
        this.$router.push("/diaoauth");
        return false;
      } else {
        this.classname = val.classname;
        // tbflag 0线上 1线下
        if (val.tbflag == "1") {
          this.cliflag = true;
          val.comtburl
            ? (window.location.href = val.comtburl)
            : this.$router.push(`/productShowNhsx?prodcode=${val.prodcode}`);
          // } else if (val.baseid === 'HDJJ') {
          //   const obj = {
          //     baseid: val.baseid,
          //     url: val.comtburl
          //   }
          //   this.getInsureUrl(obj)
        } else {
          this.$store
            .dispatch("SALES_PERMISSION", {
              url: val.comtburl,
              baseid: val.baseid,
            })
            .then((response) => {
              if (response.urltype == "W" && val.baseid == "YSYF") {
                val.tburl = `${val.comtburl.split("?")[0]}?userTrack=${
                  response.userTrack
                }`;
              } else if (response.urltype == "W" && val.baseid == "ZAZX") {
                val.tburl = `${val.comtburl.split("param=")[0]}&bizContent=${
                  response.bizContent
                }`;
              } else if (val.baseid == "ZXJJ") {
                if (val.comtburl.includes("userTrack") > -1) {
                  val.tburl = val.comtburl.replace("userTrack", response.agent);
                } else {
                  val.tburl = `${val.comtburl}&agent=${response.agent}`;
                }
              } else if (response.urltype == "W") {
                val.tburl = val.comtburl.includes("?")
                  ? `${val.comtburl}&comid=${response.comid}&prodcode=${response.prodcode}&empno=${response.empno}`
                  : `${val.comtburl}? comid=${response.comid}&prodcode=${response.prodcode}&empno=${response.empno}`;
              } else {
                val.tburl = val.comtburl;
              }
              // this.user.rytype == 'M'
              //   ? (window.location.href = val.tburl)
              //   : this.behaviorRecord(val)
              window.location.href = val.tburl;
              //  const scrollObj =  _this.$refs.scrollView.getOffsets()
              //  console.log(scrollObj)
              // setStorage('productScollTop',this.productScollTop)
              // setStorage('productList', this.articlelist)
            })
            .finally(() => {
              this.cliflag = true;
            });
        }
      }
    },
    // 行为记录
    behaviorRecord(val) {
      let data = {
        suserid: this.user.suserid,
        empno: this.user.empno,
        sno: val.prodcode,
        otype: "1",
        btagcode: "7",
        rytype: this.user.rytype,
      };
      DoRecord(data).then((res) => {
        let id = res.data.data.id;
        let comid = this.user.comid;
        this.initWebSocket(id, comid); // 开启websocket连接
        window.location.href = val.tburl;
      });
    },
    // 点击计划书
    jhsClick(item) {
      this.$router.push({
        path: "makeout",
        query: {
          cpcode: item.prodcode,
          baseid: "",
          type: "CP",
          onetype: "true",
        },
      });
      // window.location.href = `http://${window.location.hostname}/makeout?cpcode=${item.prodcode}&baseid=''&type=CP&onetype=true`
    },
    // 首佣
    fristcommission(item) {
      this.itemtk = item;
      console.log(item);
      // if (item.promotionpriceurl) {
      // this.tgfimg = item.promotionpriceurl
      this.isPopupShow = true;
      this.isloadingShow = true;
      let data = {
        prodcode: item.prodcode,
      };
      getCPXZRate(data).then((res) => {
        this.promotionFee = res.data.data.title;
        this.promotionFeeInfo = res.data.data.info;
        this.isloadingShow = false;
        console.log(this.promotionFeeInfo.length);
      });
      // }
    },
    hidePopUp() {
      this.isPopupShow = false;
    },
    // 点击签单必看
    qdClick(item) {
      this.$router.push({
        path: "information",
        query: { prodcode: item.prodcode },
      });
    },
  },
};
</script>

<style scoped lang="stylus" >
.content {
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
}

.top {
  height: 1.4rem;
  width: 100vw;
  display: flex;
  background-color: #FAFAFA;
}

.top_search {
  display: flex;
}

.top_search-left {
  display: flex;
  height: 100%;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  font-size: 0.38rem;
  font-weight: 400;
  font-family: Microsoft YaHei;
  color: rgba(102, 102, 102, 1);
}

.top_search-right {
  display: flex;
  width: 0.5rem;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.top_search-left img {
  margin-left: 0.2rem;
  width: 0.15rem;
  height: 0.15rem;
}

.searchbox {
  display: flex;
  height: 100%;
  width: 7.3rem;
  align-items: center;

  .searchline {
    background: #F2F2F2;
    height: 0.87rem;
    width: 85%;
  }

  .btnicon {
    width: 16%;
    height: 0.87rem;
    border-radius: 0.3rem 0 0 0.3rem;
    background: none;
  }

  input {
    height: 0.87rem;
    border: none;
    background: none;
    font-size: 0.33rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #A5A5A5;
    position: absolute;
  }

  .btnclear {
    background: none;
    position: absolute;
    right: 1.4rem;
    line-height: 0.95rem;
  }

  .searchTxt {
    font-size: 0.36rem;
    color: #474747;
    margin-left: 0.25rem;
  }
}

.con_centet {
  display: flex;

  .con_center-left {
    height: 88vh;
    background-color: #f2f2f2;

    .cen_left-bom {
      margin-bottom: 1.5rem;
    }

    .cen_left {
      display: flex;
      align-items: center;
      width: 2.3rem;
      height: 1.2rem;

      .cen_left-title {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 0.1rem;
        height: 0.3rem;
        background-color: #ff5a36;
        margin-left: 0.05rem;
      }

      .cen_left-title2 {
        background-color: #FAFAFA;
        // height 100%
      }

      .cen_left-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 0.36rem;
        color: #333333;
        font-weight: bold;
        font-family: Microsoft YaHei;
        background-color: #FAFAFA;
      }

      .cen_left-text2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 0.36rem;
        color: #666666;
        background-color: #F2F2F2;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
    }
  }

  .cen_right-bac {
    background-color: #ffffff;
  }

  .con_center-right {
    display: flex;
    height: 88vh;
    width: 100%;
    align-items: center;
    margin-left: 0.2rem;

    // margin-bottom 2rem
    .asdasdqwe {
      text-align: center;
      color: #c4c4c4;
    }

    .md-scroll-view-more {
      position: fixed;
      bottom: -4rem;
      left: 35%;
    }

    .cen_right-bom {
      margin-bottom: 1.5rem;
    }

    .cen_right {
      width: 7.3rem;
      height: 4.2rem;
      border: 1px solid #F7F7F7;
      margin-top: 0.3rem;
      background-color: #ffffff;
      border-radius: 0.2rem;
      // box-shadow: 0 0 0.13333rem 0.13333rem rgba(49,47,41,.04);
      box-shadow: 0 0.15rem 0.05rem -0.05rem #EBEBEB;

      .cen_right-top {
        height: 75%;
        border-bottom: 1px solid #F7F7F7;

        .top-title {
          font-size: 0.42rem;
          margin-left: 0.3rem;
          font-weight: 400;
          height: 1rem;
          line-height: 1rem;
          padding-top: 0.1rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .product-features {
          display: flex;
          border-top: 1px #f7f7f7 solid;
          padding-left: 0.3rem;
          justify-content: flex-start;
          height: 0.8rem;
          align-items: center;

          div {
            padding: 0.02rem 0.15rem;
            font-size: 0.27rem;
            line-height: 0.44rem;
            text-align: center;
            color: #FF5A36;
            margin-right: 0.2rem;
            border-radius: 6px;
          }
        }

        .product-commission {
          float: right;
          width: 1.2rem;
          font-size: 0.27rem;
          height: 0.44rem;
          line-height: 0.44rem;
          margin-right: 0.4rem;
          margin-top: -0.62rem;
          color: #FF5A36;
          border-radius: 6px;
          text-align: center;
        }

        .product-introduce {
          width: 90%;
          margin-left: 0.3rem;
          margin-top: 0.15rem;
          font-size: 0.29rem;
          color: #9A9A9A;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .cen_right-bottom {
        height: 25%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px 0.4rem;

        div {
          font-size: 0.34rem;
        }

        .cen_qd {
          display: flex;
          width: 2.2rem;
          height: 0.6rem;
          // color #FF5A36
          background: #FFF5EC;
          justify-content: center;
          align-items: center;
          border-radius: 0.28rem;
          // border 1px solid #FFF5EC
          background-image: linear-gradient(to top, #FFCEC3, #FFEFE6);
        }

        .right_bom-cen {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 4rem;

          .cen-img {
            max-width: 0.8rem;
            max-height: 0.8rem;
            line-height: 0.8rem;
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
            margin-right: 0.2rem;
            margin-top: 0.05rem;
          }

          .cen_company {
            color: #666666;
            font-size: 0.3rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.md-popupleft /deep/.md-slide-right {
  width: 82.6%;
  background-color: #ffffff;
}

.popupleft-title {
  margin-bottom: 0.61rem;
  font-size: 0.46rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: #3d3d3d;
  margin-top: 0.97rem;
  margin-left: 0.4rem;
}

.titlecontent {
  display: flex;
  flex-wrap: wrap;
}

.company-title {
  padding: 0.2rem 0.45rem;
  background-color: #f5f5f5;
  margin-right: 0.3rem;
  margin-bottom: 0.25rem;
  font-size: 0.33rem;
  border-radius: 0.1rem;
  color: #707070;
}

.companytitle {
  padding: 0.2rem 0.45rem;
  background-color: #fb6822;
  margin-right: 0.3rem;
  margin-bottom: 0.25rem;
  font-size: 0.33rem;
  border-radius: 0.1rem;
  color: #fff;
}

.bottom-cz {
  height: 1.23rem;
  width: 100%;
  position: absolute;
  display: flex;
  bottom: 0;
  border-top: 0.01rem solid #f5f5f5;
}

.bottom-chongz {
  width: 50%;
  display: flex;
  align-items: center;
}

.bottom-qued {
  width: 50%;
  display: flex;
  align-items: center;
}

.chongzhi {
  margin: auto;
  color: #383838;
  font-family: PingFang-SC-Medium;
  font-size: 0.43rem;
}

.queding {
  margin: auto;
  color: #ffffff;
  font-family: PingFang-SC-Medium;
  font-size: 0.43rem;
}

.contentscroll {
  calc(100vh - 2rem);
}

.bottomframe {
  z-index: 999;
}

.bottomframe /deep/ .md-slide-up {
  height: 60vh;
}

.inner-popup/deep/.title {
  font-weight: 600 !important;
  font-size: 0.5rem !important;
  color: #383838 !important;
}

.inner-popup/deep/.large-radius {
  position: fixed !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 1.5rem;
}

.inner-popup/deep/.title-bar-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.inner-popup/deep/ .md-icon {
  font-size: 0.8rem;
}

.disstable {
  padding: 1.9rem 0.5rem 0.8rem;
}

.asdasd {
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
  margin-left: 3.4rem;

  .asdasdqwe {
    color: #999999;
  }
}

.tgf_box {
  width: 9rem;
  overflow: auto;
  margin: 0 auto;
}

.pftable {
  width: 100%;
  margin: 0 auto;
  table-layout: fixed;
}

.pftable th {
  border: 1px solid #fff;
  background-color: #f2f2f2;
  font-size: 28px;
  height: 0.8rem;
  padding: 0.1rem;
  font-weight: bold;
  text-align: center;
}

.firstcos {
  background-color: #fce9db !important;
}

.pftable td {
  border: 1px solid #fff;
  background-color: #f2f2f2;
  font-size: 28px;
  height: 0.8rem;
  padding: 0.1rem;
  text-align: center;
}

/deep/ .icdoling {
  width: 60vh;
  text-align: center;
  margin-top: 4rem;
}

/deep/ .icdoling .md-icon {
  width: 1.42rem;
  height: 1.42rem;
  line-height: 1.42rem;
}
</style>
